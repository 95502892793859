.mainBodyEntry {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: stretch;
    gap: 10px;
}

.daterange {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: center;
    font-size: .8em;
    margin-top: 5px;
    flex: 0 0 5ch;
    line-height: 1.1;
}

.body {
    border-left: solid 1px black;
    padding: 0 10px 0 10px;
    position: relative;
}

.subheading {
    opacity: 0.5;
    font-style: italic;
    font-size: .9em;
}

.timelineDot {
    background-color: currentColor;
    height: 7px;
    aspect-ratio: 1;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: .5em;
    transform: translate(calc(-50% - 0.5px), 0);
}

.timelineDot.--first::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: white;
    top: -10.08px;
    left: 0;
}

.description p:last-child {
    margin-bottom: 2em;
}
