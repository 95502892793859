@import "styles/fonts.css";
@import "styles/variables/typography.css";
@import "styles/variables/colors.css";

@page {
  size: 8.5in 11in;
}

* {
  box-sizing: border-box;
}

body,
html {
  background-color: var(--background-color-main-body);
  color: var(--color-main-body);
  font-size: 13px;
  font-family: var(--font-family-quicksand);
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.03em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
