.mainBodySection {
    padding: 30px 0 30px 30px;
}

.heading {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: center;
    flex: 1 1;
    gap: 5px;
    text-transform: uppercase;
    white-space: nowrap;
}

.horizontalRule {
    flex: 1 1 100%;
}

.horizontalRule hr {
    border: transparent;
    height: 2px;
    background-color: black;
    border-radius: 2px;
}

.entryList {
    margin-top: 20px;
}
