.banner {
    margin-top: 36px;
    height: 190px;
    padding: 45px;
    background-color: #1f1b462e;
}

.headline {
    font-family: var(--font-family-secondary);
    font-size: 78px;
    letter-spacing: .05em;
    line-height: 1;

    background-clip: text;
    background-image: url("../../assets/images/flowers.jpg");
    text-fill-color: transparent;

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;

    background-size: 44%;
    -webkit-text-stroke: 2px var(--background-color-sidebar);
}

.subheading {
    font-family: var(--font-family-secondary);
    letter-spacing: .1em;
    margin-left: 159px;
    font-size: 19px;
}

/* Image by pikisuperstar on Freepik */
