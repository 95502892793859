.headshot {
    aspect-ratio: 1;
    background: indianred;
    border-radius: 50%;
    overflow: hidden;
    width: 100%;
}

.headshot img {
    transform: translateY(-45px);
    width: 100%;
}
