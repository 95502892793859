.container {
    border: solid 1px black;
    margin: 0 auto;
    min-height: 11in;
    width: 8.5in;
    position: relative;
}

.layout {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: stretch;
    position: absolute;
    inset: 0;
}

.sidebar {
    background-color: var(--background-color-sidebar);
    color: var(--color-sidebar);
    flex: 0 0 33.3333%;
    padding: 30px;

    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: stretch;
    position: relative;
}

.mainContent {
    flex: 0 0 66.6666%;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: stretch;
}
