.buzzwordList {
    --column-gap: 20px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-items: flex-start;
    column-gap: var(--column-gap);
}

.buzzwordList li {
    white-space: nowrap;
    flex: 1 0 calc(50% - var(--column-gap));
}
