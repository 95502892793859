.profile {
    margin-top: 20px;
}

.heading {
    text-transform: uppercase;
    text-align: center;
}

.profile > p:not(:last-child) {
    margin: 0 0 1em 0;
}
