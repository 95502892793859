@font-face {
    font-family: "Quicksand";
    src: url('../assets/fonts/Quicksand/Quicksand-VariableFont_wght.ttf') format("truetype-variations");
    font-weight: 1 1000;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Righteous";
    src: url('../assets/fonts/Righteous/Righteous-Regular.ttf');
    font-weight: 1 1000;
    font-style: normal;
    font-display: swap;
}
