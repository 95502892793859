.sidebarEndcap {
    background-image: url("../../assets/images/flowers.jpg");
    height: 130px;
    width: 100%;
    background-size: 130%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.sidebarEndcap::before {
    content: "";
    position: absolute;
    height: 175px;
    top: -45px;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, var(--background-color-sidebar) 25%, rgba(0,0,0,0) 90%);
}
