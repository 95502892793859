.contactMethods li {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: center;
    gap: 0.5em;
}

.contactMethods li:not(:first-child) {
    margin-top: 10px;
}

.icon {
    aspect-ratio: 1;
    display: inline-block;
    width: 1.5em;
    line-height: 0;
    border: solid 1px currentColor;
    border-radius: 50%;
    position: relative;
}

.icon > svg {
    height: 65%;
    fill: currentColor;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.label {
    margin-top: -2px;
}
